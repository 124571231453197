// A mapping of content types with page data to be shared between page components and modular components
const entryMap = {
    // Press releases - has dedicated page, and can be featured in a Content Listing component
    pressReleases: {
        entryID: "ContentfulPressRelease",
        pageName: "Press releases",
        pagePath: "/about-us/press-releases",
        pageComponent: "newsEntryListingPage",
        itemsPerPage: 10,
    },
    // News Articles - has dedicated page, and can be featured in a Content Listing component
    newsArticles: {
        entryID: "ContentfulNewsArticle",
        pageName: "News articles",
        pagePath: "/about-us/articles",
        pageComponent: "newsEntryListingPage",
        itemsPerPage: 10,
    },
};

module.exports.entryMap = entryMap;
