import React from "react";
import { graphql } from "gatsby";
import Layout from "../../components/layout/Layout";
import SEO from "@v4/gatsby-theme-talend/src/components/Seo/Seo";
import { Helmet } from "react-helmet";
import formatContentfulRichText from "@v4/talend/src/utils/formatContentfulRichText";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import Breadcrumbs from "../../components/breadcrumbs/Breadcrumbs";
import { entryMap } from "../../utils/entryMap";
import { newsroomTranslations } from "../../translations/newsroom";
import SocialSharing from "../../components/socialSharing/SocialSharing";
import MarketoForm from "../../components/marketoForm/MarketoForm";

// Styling
import * as prStyles from "./pressReleasePage.module.css";
import classNames from "classnames/bind";

export const query = graphql`
    query($id: String!, $locale: String!) {
        contentfulPressRelease(id: { eq: $id }) {
            ...PressRelease
        }
        contentfulMenu(name: { eq: "Main Nav" }, node_locale: { eq: $locale }) {
            ...MainMenu
        }
        footerNav: contentfulMenu(name: { eq: "Footer Nav" }, node_locale: { eq: $locale }) {
            ...FooterNav
        }
        footerSocials: contentfulMenu(name: { eq: "Footer Socials" }, node_locale: { eq: $locale }) {
            ...FooterSocials
        }
        footerSubMenu: contentfulMenu(name: { eq: "Footer Sub Menu" }, node_locale: { eq: $locale }) {
            ...FooterSubMenu
        }
    }
`;

export default function PressRelease({ data }) {
    // Shortcut
    const pressRelease = data.contentfulPressRelease;

    // Data for use with footer components
    const footerData = {
        footerNav: data.footerNav,
        footerSocials: data.footerSocials,
        footerSubMenu: data.footerSubMenu,
    };

    // Meta values for the SEO component
    const metaValues = pressRelease.metaFields ?? {};

    // Slug with (non-lang) path-prefix
    const pagePath = `${entryMap.pressReleases.pagePath}/${pressRelease.slug}`;

    // Set up style props, CX binding method
    const styleProps = {};
    const cx = classNames.bind(prStyles);

    const translations = newsroomTranslations[pressRelease.node_locale];

    // Set up breadcrumbs object
    let crumbList = {
        [translations.breadcrumbs.newsroom]: "/about-us/news",
        [translations.breadcrumbs.pressReleases]: "/about-us/press-releases",
        [pressRelease.title]: `/${pressRelease.slug}`,
    };

    return (
        <Layout
            headerMenu={data.contentfulMenu}
            footerMenu={footerData}
            notificationBar={pressRelease.notificationBar}
            pageStyleProps={styleProps}
        >
            <SEO
                title={pressRelease.title}
                description={metaValues?.metaDescription}
                modifiedTime={pressRelease.updatedAt}
                createdTime={pressRelease.createdAt}
                languages={pressRelease.languages}
                metaImage={metaValues?.metaImage ?? null}
                type={`NewsArticle`}
            />
            <Helmet bodyAttributes={{ class: "darkOpaque" }} />
            <section className={cx("container")}>
                <article className={cx("prWrapper")}>
                    <h1 className={cx("prTitle")}>{pressRelease.title}</h1>
                    <SocialSharing
                        title={`${pressRelease.title} - Read more: `}
                        slug={pagePath}
                        locale={pressRelease.node_locale}
                    />
                    <Breadcrumbs crumbList={crumbList} />
                    <div className={cx("prBody")}>
                        {pressRelease.body?.raw && renderRichText(pressRelease.body, formatContentfulRichText())}
                    </div>
                </article>
            </section>
            {pressRelease.form && (
                <section className={cx("formContainer")}>
                    <div className={cx("marketoForm")}>
                        <MarketoForm {...pressRelease.form} />
                    </div>
                </section>
            )}
        </Layout>
    );
}
